// Generated by Framer (7f988a2)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle1 from "../css/qcg0OEbTe";
import * as sharedStyle from "../css/TcMnAA6cP";

const cycleOrder = ["ZXzlQcg3P"];

const serializationHash = "framer-pND4I"

const variantClassNames = {ZXzlQcg3P: "framer-v-1wpagso"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({counter, desc, height, id, width, ...props}) => { return {...props, hU7fVM9Sx: desc ?? props.hU7fVM9Sx ?? "desc", NUTAjMFYP: counter ?? props.NUTAjMFYP ?? "Counter"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;counter?: string;desc?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, NUTAjMFYP, hU7fVM9Sx, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ZXzlQcg3P", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1wpagso", className, classNames)} data-framer-name={"Counter"} layoutDependency={layoutDependency} layoutId={"ZXzlQcg3P"} ref={ref ?? ref1} style={{background: "linear-gradient(235deg, rgba(255, 255, 255, 0.14) 0%, rgba(222, 232, 246, 0.1) 100%)", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.14)", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h3 className={"framer-styles-preset-e9hprd"} data-styles-preset={"TcMnAA6cP"}><motion.span data-text-fill={"true"} style={{backgroundImage: "linear-gradient(110deg, var(--token-43315af6-1007-47b0-b37f-7a6ce15fe95c, rgb(242, 249, 254)) 20%, var(--token-3e7c9ca2-00c4-481f-8e38-20dd1fdd0e32, rgb(212, 237, 255)) 45%)"}}>150k+</motion.span></motion.h3></React.Fragment>} className={"framer-1u70fry"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"zU3YlPYwE"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={NUTAjMFYP} verticalAlignment={"top"} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1p685yq"} data-styles-preset={"qcg0OEbTe"} style={{"--framer-text-alignment": "center"}}>desc</motion.p></React.Fragment>} className={"framer-14oicb5"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"XWS_ZALPX"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={hU7fVM9Sx} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pND4I.framer-3r04pa, .framer-pND4I .framer-3r04pa { display: block; }", ".framer-pND4I.framer-1wpagso { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 28px 34px 28px 34px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-pND4I .framer-1u70fry { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-pND4I .framer-14oicb5 { align-self: stretch; flex: none; height: auto; position: relative; white-space: pre-wrap; width: auto; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-pND4I.framer-1wpagso { gap: 0px; } .framer-pND4I.framer-1wpagso > * { margin: 0px; margin-bottom: calc(5px / 2); margin-top: calc(5px / 2); } .framer-pND4I.framer-1wpagso > :first-child { margin-top: 0px; } .framer-pND4I.framer-1wpagso > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 141
 * @framerIntrinsicWidth 232.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"NUTAjMFYP":"counter","hU7fVM9Sx":"desc"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramercmfZwj4Qt: React.ComponentType<Props> = withCSS(Component, css, "framer-pND4I") as typeof Component;
export default FramercmfZwj4Qt;

FramercmfZwj4Qt.displayName = "Card Counter";

FramercmfZwj4Qt.defaultProps = {height: 141, width: 232.5};

addPropertyControls(FramercmfZwj4Qt, {NUTAjMFYP: {defaultValue: "Counter", displayTextArea: false, title: "Counter", type: ControlType.String}, hU7fVM9Sx: {defaultValue: "desc", displayTextArea: false, title: "Desc", type: ControlType.String}} as any)

addFonts(FramercmfZwj4Qt, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts)], {supportsExplicitInterCodegen: true})